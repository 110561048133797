<template>
  <div>
    <canvas v-show="false" ref="canvasElement"></canvas>
    <video v-show="!useDewarp" class="video-player-size" ref="videoElement" autoplay muted></video>
  </div>
</template>

<script>
import {
  reactive,
  toRefs,
  watch,
  onMounted,
  ref,
  computed,
  onBeforeUnmount,
} from "vue";
import UInt8ArrayWorker from "worker-loader!../../workers/h264parser.worker.js";
import restapi from "@eencloud/core-components/src/service/CMApi";
import {t} from '@eencloud/core-components/src/service/locale'
import {store} from "@/store"

const FIRST_FRAME_OFFSET = 2; // We ignore the first two frames to get a representative timestamp. The first 2 frames can be off.
const INIT_BUFFER_LENGTH = 500; //start with a buffer of 500ms

export default {
  name: "LLLP",
  props: {
    url: String,
    resolution: String,
    autoplay: Boolean,
    maxBuffer: Number,
    framerate: Number,
    cameraId: Number,
    useDewarp: Boolean,
    audioMuted: Boolean,
    audioVolume: Number,
    audioCodec: String,
    debugToolsOpen: Boolean
  },
  setup(props, context) {
    const canvasElement = ref(null);
    const videoElement = ref(null);
    let data = reactive({
      alive: true,
      canvasResolution: computed(() => (props.resolution ? props.resolution : "1920x1080")),
      offset: computed(() => {
        if (!data.firstFrameTime || !data.firstFrameTimeStamp) return 0;
        return (data.firstFrameTime - data.firstFrameTimeStamp) / 1000;
      }),
      firstFramePassed: computed(() => data.firstFrameCounter > FIRST_FRAME_OFFSET),
      isPlaying: false,
      isConfigured: false,
      SPS: new Uint8Array(),
      PPS: new Uint8Array(),
      reader: null,
      decodedVideoBuffer: [], // not being use right now
      encodedAudioBuffer: [],
      isStalled: false,
      bufferLength: INIT_BUFFER_LENGTH,
      isDecoderDelayed: computed(() => data.debugInfo.queueSizes.decoder > 5),
      firstFrameTimeStamp: null, //timestamp of first frame.
      firstFrameTime: null, //timestamp in computer clock time when the first frame was displayed.
      firstFrameCounter: 0, // goes up to firstRepresentativeFrameNumber
      firstRepresentativeFrameNumber: FIRST_FRAME_OFFSET + 1,
      firstAudioframeTimeStamp: 0,
      audioVideoOffset: 0, // the offset between the first audio frame timestamp and the first video frame timestamp
      chunkBuffer: [],
      statusInfo: {
        connectingTimeOut: null,
        bufferingTimeOut: null,
        showConnectivityIssues: false,
        bufferCounter: 0,
        resetBufferCounter: null,
        decoderDelayTimeout: null,
      },
      debugInfo: {
        avgBandwidth: 0,
        bandwidthUsageArray: [],
        queueSizes: {
          encoded: 0,
          decoder: 0,
          decoded: 0,
        }
      },
      usedBandwitdhInTheLastSecond: 0,
      debugInterval: null,
      encodedAudioChunkConfig: {},
      waitForKeyFrame: true, // the first frame after configuring should be a key frame
      decoder: null,
      audioDecoder: null,
      cameraAudioCodec: computed(() => {
        const audioCodecs = {
          aac: {
            codec: "mp4a.40.2",
            mime: "audio/aac-lc",
          },
          g711PcmALaw: {
            codec: "alaw",
            mime: "audio/alaw",
          },
          g711PcmULaw: {
            codec: "ulaw",
            mime: "audio/ulaw",
          }
        }
        const foundSupportedAudioCodec = audioCodecs[props.audioCodec] 
        return foundSupportedAudioCodec ? foundSupportedAudioCodec : "" 
      }),
      arrayWorker: null,
      audioCtx: null,
      audioBuffer: [],
      bufferSource: null,
      playTime: 0,
      gainNode: null, // volume control node attached to the audio context
      duration: null,
      audioPlayBackRate: 1,
      nextFrameDelay: 0, // adjusted the duraration of a frame, ragardless of the playbackspeed, to force prevent extreme audio lag
      audioDetected: false,
      audioReference: { timeStamp: 0, age: 0},
      audioVideoDelays: [],
      bufferAccumulation: null,
      bufferAccumulationTimeStamp: null,
      bufferAccumulationCount: 0,
      bufferAccumulationMultiplier: 20, // this is a static setting to determine the size of the decoded buffer. We wait this many audioData frames before we release the accumulation.
      lastTimeStampEmitTime: 0,
      lastTimeStamp: 0,
      lastAudioPlayTime: 0,
      controller: null,
      signal: null,
      h264Profile: null,
      timePerFrame: computed(() => 1000 / props.framerate),
      decodedFrameBuffer: [],
      ctx: null, // https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D
      mediaStream: null, // https://developer.mozilla.org/en-US/docs/Web/API/MediaStream
      fallbackWidthHeight: [1280, 720],
    });

    const { updateDebugData } = useDebugTools(data, context, props)
    const { stop, start } = useParse(data, props, context, canvasElement, videoElement, updateDebugData);

    return { ...toRefs(data), stop, start, canvasElement, videoElement };
  },
};

function useParse(data, props, context, canvasElement, videoElement, updateDebugData) {

  onMounted(() => {
    data.controller = new AbortController();
    data.signal = data.controller.signal;
    window.addEventListener("online", reConnect);

    setupArrayWorker();
    if (props.autoplay) start();
  });

  watch(
    () => data.isPlaying,
    (value) => {
      if (value) {
        context.emit("play");
      } else {
        context.emit("pause");
      }
    }
  );

  watch(
    () => [data.isStalled, data.isDecoderDelayed],
    () => {
      if (document.visibilityState === "visible") calculateTimesAndShowMessages();
    }
  );

  watch(
    () => data.firstFrameCounter,
    () => {
      if (data.firstFrameCounter) {
        clearInterval(data.statusInfo.connectingTimeOut);
        context.emit("emit-lllp-status-message", null);
      }
    }
  );

  watch(() => props.maxBuffer, newMaxBufferLength => {
    if(data.bufferLength > newMaxBufferLength) {
      data.bufferLength = newMaxBufferLength
    }
  })

  async function audioSyncedRender(frame) {
    try{
      //schedule the frame to sync with the audio
      const waitTime = (frame.timestamp / 1000) - (data.audioReference.timeStamp + (performance.now() - data.audioReference.age))
      const timeStamp = frame.timestamp / 1000

      if(waitTime > 0) await delay(waitTime);
      
      if(data.lastTimeStamp > timeStamp) return frame.close();
      if(props.audioMuted) return frame.close();
      if(!data.isPlaying || !frame) return frame.close();

      drawFrame(frame);

      data.lastTimeStamp = timeStamp
      //emit timestamp for displaying on top of the video, but only if audio is not leading the schedule, and rather per second than per frame
      if(performance.now() - data.lastTimeStampEmitTime > 1000) {
        context.emit("emit-timestamplllp-in-miliseconds", timeStamp)
        data.lastTimeStampEmitTime = performance.now()
        if (props.debugToolsOpen) {
          context.emit("calculatedBufferLengthUpdate", (waitTime / 1000).toFixed(2))
        }
      }
      // since audio is leading, we can process any frames and potentially drop them based on audio time
      if (!data.firstFramePassed) data.firstFrameCounter = data.firstRepresentativeFrameNumber
    } catch(error) {
      console.error(error)
      stop()
    }
  }

  async function timedRender() {
    try{
      const frame = data.decodedFrameBuffer.shift()
      const msTimeStamp = frame.timeStamp / 1000

      if (frame.waitTime > 0 ) { // if waitTime is positive we wait
        await delay(frame.waitTime)
      } else if(data.firstFramePassed) { // if waitTime is negative we increase the buffer with the waitTime
        let increasedBuffer = data.bufferLength + Math.abs(frame.waitTime);
        if(increasedBuffer < props.maxBuffer) {
          data.bufferLength = increasedBuffer;
          console.log("Increased buffer to", data.bufferLength, "ms");
        }
      }

      drawFrame(frame.data);

      //emit a timestamp
      if(performance.now() - data.lastTimeStampEmitTime > 1000) {
        context.emit("emit-timestamplllp-in-miliseconds", msTimeStamp)
        data.lastTimeStampEmitTime = performance.now()
        if (props.debugToolsOpen) {
          context.emit("calculatedBufferLengthUpdate", (frame.waitTime / 1000).toFixed(2))
        }
      }
    
      //the first two timestamps we get can be off
      if (data.firstFrameCounter === FIRST_FRAME_OFFSET) {
        data.firstFrameCounter++;
        data.firstFrameTime = Date.now() * 1000;
        data.firstFrameTimeStamp = frame.timeStamp;
      } else if (data.firstFrameCounter < data.firstRepresentativeFrameNumber) {
        data.firstFrameCounter++;
      }

      data.alive = true
    } catch(error) {
      console.error(error)
      stop()
    }
  }

  function drawFrame(frameData) {
    //render the frame if the tab is visible, doing otherwise is causing a memory leak on Chrome as the drawing process seems to be queued up
    if (document.visibilityState === "visible" && data.ctx && canvasElement.value) { 
      requestAnimationFrame(() => {
        
        // scaling the images to the current size of the video element to counter the effects of aliasing
        const factor = (videoElement.value.clientWidth || data.fallbackWidthHeight[0]) / frameData.codedWidth;
        const drawWidth = factor * frameData.codedWidth;
        const drawHeight = factor * frameData.codedHeight;

        canvasElement.value.width = drawWidth;
        canvasElement.value.height = drawHeight;

        data.ctx.drawImage(frameData, 0, 0, drawWidth, drawHeight);
        
        data.alive = true;
        frameData.close();
      });
    } else {
      frameData.close();
    };
  }

  function prepareCanvas() {
    data.ctx = canvasElement.value.getContext('2d');
    canvasElement.value.width = videoElement.value.clientWidth || data.fallbackWidthHeight[0];
    canvasElement.value.height = videoElement.value.clientHeight || data.fallbackWidthHeight[1];
    data.mediaStream = canvasElement.value.captureStream(25); // https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/captureStream
    videoElement.value.srcObject = data.mediaStream;
  }
  

  function onFrame(frame) {
    try {
      if (!data.isPlaying || !frame) return frame.close();

      if (videoElement.value && !data.ctx ) {
        prepareCanvas()
      }

      if(data.audioReference.timeStamp) {
        data.decodedFrameBuffer = []
        if(props.audioMuted) {
          return data.audioReference = { timeStamp: 0, age: 0 };
        }
        audioSyncedRender(frame);
      } else {
        const targetDisplayTimestamp = frame.timestamp/1000 + data.offset + data.bufferLength
        const waitTime = data.firstFramePassed ? (targetDisplayTimestamp - Date.now() ) : 0

        if (waitTime < -data.bufferLength) { //don't render old frames
          context.emit("emit-dropped-frame")
          return frame.close()
        }
        data.decodedFrameBuffer.push({
          data: frame, 
          timeStamp: frame.timestamp,
          waitTime
        })

        if (videoElement.value && !data.ctx ) {
          prepareCanvas()
        }

        timedRender()
      }
    } catch(error) {
      console.error(error)
      stop()
    }
  }

  function onAudioFrame(audioData) {
    // AAC  example: {duration: 64000 //microseconds, format: "f32-planar", numberOfChannels: 1, numberOfFrames: 1024, sampleRate: 16000, timestamp: 1629790319829000 //microseconds }
    // G711 example: {format: 's16', sampleRate: 8000, numberOfFrames: 962, numberOfChannels: 1, duration: 120250, …}
    try {
      data.duration = audioData.duration / 1000
    
      if (!data.bufferAccumulation) { // Create an f32 buffer we fill with <bufferAccumulationMultiplier> audioData frames before sending it to the player
        data.bufferAccumulation = new Float32Array(audioData.numberOfFrames * data.bufferAccumulationMultiplier);
        data.bufferAccumulationTimeStamp = audioData.timestamp
      }

      const options = { planeIndex: 0 }
      if (audioData.format !== "f32-planar") options.format = "f32-planar"
      let tempArrayBuffer = new Float32Array(audioData.numberOfFrames)
      audioData.copyTo(tempArrayBuffer, options)
      data.bufferAccumulation.set(tempArrayBuffer, audioData.numberOfFrames * data.bufferAccumulationCount)
      data.bufferAccumulationCount++

      if (data.bufferAccumulationCount < data.bufferAccumulationMultiplier) return
      
      let audioBuffer = data.audioCtx.createBuffer(1, audioData.numberOfFrames * data.bufferAccumulationMultiplier, audioData.sampleRate)
      audioBuffer.copyToChannel(data.bufferAccumulation, 0);
      let bufferSource = data.audioCtx.createBufferSource()
      bufferSource.buffer = audioBuffer
      bufferSource.connect(data.audioCtx.destination)

      bufferSource.connect(data.gainNode).connect(data.audioCtx.destination)
      data.gainNode.gain.value = props.audioVolume

      data.audioBuffer.push({ bufferSource, timestamp: data.bufferAccumulationTimeStamp, duration: audioData.duration / 100}) // push the buffer source node to the array so we can play it later
      scheduleAndPlayAudioFragment()

      audioData.close()
      data.bufferAccumulation = null
      data.bufferAccumulationCount = 0

    } catch (error) {
      context.emit("emit-mute-audio");
      console.error(error)
    }
  }

  /**
   * Calculates the offset between the first audio frame timestamp and the first video frame timestamp.
   * ideally this should be 0, but we know from experience, it can be very off (see EEPD-73793)
   */
  function calculateAudioVideoOffset(newAudioTimeStamp) {
    if (newAudioTimeStamp && !data.firstAudioframeTimeStamp && !!data.firstFrameTimeStamp) {
      data.firstAudioframeTimeStamp = newAudioTimeStamp * 1000;
      data.audioVideoOffset = (data.firstFrameTimeStamp - data.firstAudioframeTimeStamp) / 1000;
      context.emit("emit-audio-video-offset", data.audioVideoOffset);
    }
    return data.audioVideoOffset;
  }

  function scheduleAndPlayAudioFragment() {
    try{
      if (!data.isPlaying || props.audioMuted) return

      if (data.audioBuffer.length > 1) {
        context.emit("emit-audio-buffer-size", data.audioBuffer.length);
        const sourceNode = data.audioBuffer.shift()
        if (data.audioBuffer.length > 3) return // skip an audio frame to maintain acceptable latency
        data.audioReference = { timeStamp: sourceNode.timestamp / 1000, age: performance.now()} // let the video player know which audio frame we play
        const duration = sourceNode?.duration ?? data.duration * data.bufferAccumulationMultiplier
        const startTime = data.lastAudioPlayTime + duration / 1000
        sourceNode.bufferSource.start(startTime)
        data.lastAudioPlayTime = data.audioCtx.currentTime
      }
    } catch(error) {
      context.emit("emit-mute-audio");
      context.emit("emit-audio-buffer-size", null);
      console.error(error)
    }
  }

  onBeforeUnmount(() => {
    try {
      stop();
      window.removeEventListener("online", reConnect);
      data.audioCtx?.close();
      data.controller.abort();
    } catch(error) {
      console.log(error)
    }
  });

  async function start() {
    if (data.isPlaying) {
      await stop();
    };

    data.decoder = new VideoDecoder({
      output: onFrame,
      error: (e) => {
        console.error(e);
      },
    });

    data.audioDecoder = new AudioDecoder({
      output: onAudioFrame,
      error: (e) => {
        console.error(e);
      },
    });

    startStream();

    data.debugInterval = setInterval(() => {
      if(props.debugToolsOpen) {
        updateDebugData();
      }
      checkIfStalled();
    }, 1000);
    
    //When the stream starts, reset to 0 the counter of the times it has buffered, every 1 minute
    data.statusInfo.resetBufferCounter = setInterval(() => {
      data.statusInfo.bufferCounter = 0;
    }, 60000);
    context.emit("play");
  }

  function reConnect() {
    setTimeout(async () => {
      console.log("reconnecting the stream")
      await stop();
      start();
    }, 1000)
  }

  function checkIfStalled() {
    if (!data.alive) data.isStalled = true;
    else data.isStalled = false;
    data.alive = false;
  }

  async function stop(stopAudioOnly = false) {
    //stop audio playback and reset audio state
    try { 
      if (data.audioDecoder?.state === "configured") 
        await data.audioDecoder?.flush();
    } catch (e) { console.log(e) }
    data.encodedAudioBuffer = [];
    data.audioBuffer = [];
    data.audioReference = { timeStamp: 0, age: 0 };
    context.emit("emit-audio-buffer-size", null);
    
    if(stopAudioOnly) return;
    
    //stop the stream
    try { 
      if (data.reader) {
        data.reader.cancel()
      };
    } catch (e) { console.log(e) }
    //stop video playback and reset video state
    try { 
      if (data.decoder?.state === "configured") 
      await data.decoder.flush()
  } catch (e) { console.log(e) }
    data.alive = false;
    data.audioVideoOffset = 0;
    data.firstAudioframeTimeStamp = 0;
    data.decodedFrameBuffer = []
    data.isPlaying = false;
    data.isConfigured = false;
    data.firstFrameTimeStamp = null;
    data.targetDisplayTimestamp = null;
    data.firstFrameCounter = 0;
    data.bufferLength = INIT_BUFFER_LENGTH;

    context.emit("emit-dropped-frame", 0)

    cleanBufferingAndFailing();

    if (data.debugInterval) {
      clearInterval(data.debugInterval);
      data.debugInterval = null;
    }

    clearInterval(data.statusInfo.resetBufferCounter);
    data.statusInfo.resetBufferCounter = null;
  }

  function delay(time_ms) {
    return new Promise((resolve) => {
      setTimeout(resolve, time_ms);
    });
  }

  function setupArrayWorker() {
    data.arrayWorker = new UInt8ArrayWorker();
    let stateData = data;
    data.arrayWorker.addEventListener(
      "message",
      ({ data }) => {
        switch (data.type) {
          case "frame":
            processEncodedVideo(data.value);
            break;
          case "audio":
            stateData.encodedAudioBuffer.push(data.value);
            processEncodedAudio();
            break;
          case "audioConfig":
            configureAudio(data.value);
            break;
        }
      },
      false
    );
  }

  function bufferToHex(buffer) {
    let s = "",
      h = "0123456789ABCDEF";
    new Uint8Array(buffer).forEach((v) => {
      s += h[v >> 4] + h[v & 15];
    });
    return s;
  }

  function startStream() {
    data.isPlaying = true;
    fetch(props.url, { signal : data.signal , headers: { 'X-Accept-Codecs': 'image/h264,' + data.cameraAudioCodec.mime } })
      .then((res) => {
        if (res.body) {
          if (res.status === 401) {
            streamUnauthorized()
          }
          data.reader = res.body.getReader();
          data.reader.read().then(function processResult(result) {
            // this method is called recurringly while the stream is going on
            // every time its called the VALUE contains a part of the ongoing data stream from the cloud.
            if (result.value) data.usedBandwitdhInTheLastSecond += result.value.length;

            if (result.done) {
              console.log("Video Stream is done.");
              return Promise.resolve();
            }

            // Send data chunk to web worker to get split into frames and prepared for the decoder.
            data.arrayWorker.postMessage(result.value, [result.value.buffer]);

            return data.reader.read().then(processResult);
          }).catch((error) => console.error(error)) 
        }
      })
      .catch((err) => {
        console.error("Video Stream Request error", err);
      });
      window.decoder = data.decoder;
  }

  function processEncodedVideo(frame) {
    if (!frame.data.length) return;

    let sampleData = frame.data

    if (sampleData[0] != 0x00 || sampleData[1] != 0x00 || sampleData[2] != 0x01) {
      console.log("missing annex b start code (0x000001) at start of NAL unit.");
      return;
    }
    let nalUnitType = sampleData[3] & 0x1f;
    let nalType = null;

    switch (nalUnitType) {
      case 1: // Non IDR slice, of type "delta"
        nalType = "delta";
        break;
      case 5: // IDR slice of type "key"
        nalType = "key";
        data.waitForKeyFrame = false;
        break;
      //case 6: SEI
      case 7: // SPS
        if (!data.isConfigured) {
          const profileLevelHex = bufferToHex(sampleData.slice(4, 7));
          if (profileLevelHex.slice(0,2) == "64") {
            data.h264Profile = "high";
          }
          const config = {
            codec: `avc1.${profileLevelHex}`,
            codedWidth: data.canvasResolution.split("x")[0],
            codedHeight: data.canvasResolution.split("x")[1],
            hardwareAcceleration: "prefer-software",
            optimizeForLatency: true
          };
          if (data.decoder.state !== "closed") {
            data.decoder.configure(config);
            data.isConfigured = true;
            data.waitForKeyFrame = true;
          }
        }
        data.SPS = sampleData;
        return;
      case 8: // PPS
        if(data.h264Profile === "high") {
          data.PPS = sampleData;
        } else {
          data.PPS = sampleData.slice(0,7);
        }
        return;
      case 9:
        console.log("AUD"); // access unit delimiter
        break;
      default:
        break;
    }
    if (data.isConfigured && !data.waitForKeyFrame) {
      const combinedData = new Uint8Array([...data.SPS, ...data.PPS, ...sampleData]);

      const chunk = new EncodedVideoChunk({
        type: nalType,
        timestamp: frame.timestamp * 1000,
        data: combinedData,
      });
      if (data.decoder.state === "configured") {
        data.decoder.decode(chunk);
      }
    }
  }
  
  function processEncodedAudio() {
    try {
      if (!data.audioDetected) {
        data.audioDetected = true
        context.emit('emit-audio-detected')
      }
      
      // We need the audioVideoOffset before playing any audio, because the audio timestamps can be off, and the audio is leading the video
      // but we need the first frame timestamp to calculate the audioVideoOffset
      // therefore we start playing video, independently of the audio
      // After the firstFramePassed, we can correct the audio timestamps and start playing audio as well
      // the video frame scheduling logic will detect the audio frames being played and adjust the video frames accordingly
      if (!data.firstFramePassed) return;

      if (props.audioMuted && data.audioBuffer.length) {
        return stop(true)
      }
      if (data.encodedAudioBuffer.length && data.audioDecoder.state === "configured") {
        let audioData = []

        let audioFragment = data.encodedAudioBuffer.shift();
        audioData = new Uint8Array(audioFragment.data);
        const timestamp = parseInt(audioFragment.timestamp);
        const offset = calculateAudioVideoOffset(timestamp);

        const audioChunk = new EncodedAudioChunk({
          type: 'key',
          timestamp: (timestamp + offset) * 1000, //microseconds
          data: audioData,
        });
        
        if (data.audioDecoder.state === "configured") 
          data.audioDecoder.decode(audioChunk)
      } else if (data.audioDecoder.state !== "configured" && props.audioCodec !== "aac") {
        // Only for AAC, we get a config from the server, the config for g.711 (alaw, ulaw) is always the same:
        configureAudio({
          "sampleDuration": 128, // ((dataLength*8*1000) / bitRate);
          "audioMode": data.cameraAudioCodec.codec,
          "sampleRate": 8000,
          "channels": 1
        })
      }
    } catch(error) {
      console.error(error);
      context.emit("emit-mute-audio");
    }
  }

  function configureAudio(configuration) {
    console.log("received audio configuration, ", configuration)
    // example:
      // "mimeParamSizeLength":13,
      // "mimeParamIndexLength":3,
      // "mimeParamIndexDeltaLength":3,
      // "sampleDuration":64,
      // "audioMode":"AAC_LC",
      // "bytesPerFrame":0,
      // "samplesPerPacket":1024,
      // "bytesPerPacket":0,
      // "sampleRate":16000,
      // "channels":1
    if (data.audioDecoder.state !== "closed") {
      data.audioDecoder.configure({
          codec: data.cameraAudioCodec.codec,
          sampleRate: configuration.sampleRate,
          numberOfChannels: configuration.channels,
      });
    }

    data.encodedAudioChunkConfig = {
      sampleDuration: configuration.sampleDuration,
    }
    if (!data.audioCtx) {
      data.audioCtx = new AudioContext()
      data.gainNode = data.audioCtx.createGain()
    }
  }

  function streamUnauthorized() {
    stop()
    videoElement.value.poster = `${restapi.baseURL}/rest/v2.4/cameras/${
      props.cameraId
    }/snapshot?resolution=1920x1080&access_token=${
      restapi.token
    }`;
    context.emit("emit-lllp-status-message", null);
    if (store.getters.supportAccess) {
      store.dispatch("toastMessage", {
        text: t("No video is available when using support-access"),
        color: "error",
        showing: true,
        timeout: -1,
        support: false
      });
    }
  }

  function calculateTimesAndShowMessages() {
    if (data.isStalled && data.isPlaying) {
      //Show buffering if data is stalled for 0.5 seconds
      data.statusInfo.bufferingTimeOut = setTimeout(() => {
        data.statusInfo.isBuffering = true;
        context.emit("emit-lllp-status-message", "Buffering");

        data.statusInfo.bufferCounter++;
        console.log("data.statusInfo.bufferCounter", data.statusInfo.bufferCounter);

        //Show connectivity issues if it has show bufffering for 5 times in a range of 30 seconds- see interval on start() function
        if (data.statusInfo.bufferCounter === 5) {
          showConnectivityIssuesMsg();
        }
      }, 500);
    } else if (data.firstFramePassed) {
      cleanBufferingAndFailing();
    }

    if (data.isDecoderDelayed) {
      data.statusInfo.decoderDelayTimeout = setTimeout(() => {
        context.emit(
          "emit-lllp-lowbandwidth-message",
          "Playback issues detected. Your computer might not have enough processing power"
        );
      }, 5000);
    } else {
      clearTimeout(data.statusInfo.decoderDelayTimeout);
      data.statusInfo.decoderDelayTimeout = null;
    }
  }

  function cleanBufferingAndFailing() {
    //Stop showing buffering
    clearTimeout(data.statusInfo.bufferingTimeOut);
    data.statusInfo.bufferingTimeOut = null;
      if(data.statusInfo.isBuffering){
        context.emit("emit-lllp-status-message", null);
      }
    data.statusInfo.isBuffering = false;
  }

  //Show connectivity issues message
  function showConnectivityIssuesMsg() {
    let lowbandwidthMessage = "Connectivity issues detected";
    context.emit("emit-lllp-lowbandwidth-message", lowbandwidthMessage);
  }

  return { stop, start };
}

function useDebugTools(data, context, props) {
  function calculatebandwidthUsage() {
    data.debugInfo.bandwidthUsageArray.push(data.usedBandwitdhInTheLastSecond);
    if (data.debugInfo.bandwidthUsageArray.length > 10) data.debugInfo.bandwidthUsageArray.shift();
    data.usedBandwitdhInTheLastSecond = 0;

    data.debugInfo.avgBandwidth = Math.round(calculateAvg(data.debugInfo.bandwidthUsageArray));
    context.emit("bandwidthUpdate", data.debugInfo.avgBandwidth / 1000);
  }
  function calculateAvg(array) {
    if (array.length > 0) {
      let sum = array.reduce((a, b) => a + b);
      return sum / array.length;
    }
    return 0;
  }

  function updateQueueSizes() {
    data.debugInfo.queueSizes.decoder = data.decoder.decodeQueueSize;
    data.debugInfo.queueSizes.decoded = data.decodedFrameBuffer.length;
    context.emit("emit-queue-sizes", data.debugInfo.queueSizes);
  }
  
  function updateDebugData() {
    calculatebandwidthUsage();
    updateQueueSizes();
  }

  return { updateDebugData };
}
</script>

<style lang="scss" scoped>
@import "../../assets/styles/main";

.video-player-size {
  object-fit: contain;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
}
</style>
